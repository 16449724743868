<template>
  <div class="deal flex gap-x-4 typeset-7 items-center">
    <img
      :src="dealImage"
      class="aspect-ratio-1x1 deal-img object-cover" />
    <div class="h-full flex flex-col justify-center">
      <h2 class="mb-3">
        <a :href="deal.ctaLink">
          <strong>{{ deal.description }}</strong>
        </a>
      </h2>
      <a
        class="underline"
        :href="deal.ctaLink"
        @click="triggerAnalyticsEvent">
        {{ deal.ctaText }}
      </a>
    </div>
  </div>
</template>

<script>
import bookGif from "@/app/assets/images/multibook-deal.gif";
import weddingGif from "@/app/assets/images/wedding-deal.gif";

export default {
  name: "ProductDeal",
  props: {
    deal: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      bookGif,
      weddingGif
    };
  },
  computed: {
    dealImage() {
      if (this.deal.name.includes("IOS")) {
        return weddingGif;
      }
      return this.nonWeddingImage;
    },
    nonWeddingImage() {
      return this.imageUrlExists ? this.deal.imageUrl : bookGif;
    },
    imageUrlExists() {
      return !this.deal.imageUrl.includes("missing.png");
    }
  },
  mounted() {
    analytics.track("Viewed Deal Indicator", {
      deal: this.deal.name
    });
  },
  methods: {
    triggerAnalyticsEvent() {
      analytics.track("Clicked Deal Indicator", {
        deal: this.deal.name
      });
    }
  }
};
</script>
